import React, { useEffect } from "react";
import "./App.css";
// third party imports
import AOS from "aos";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";
// import ScrollToTop from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes";
// css imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "photoswipe/dist/photoswipe.css";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Jon-Michael Narvaez | Full-Stack Creative</title>
        <meta name="description" content="React App" />
      </Helmet>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="220, 53, 69"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />

      <AllRoutes />
    </>
  );
}

export default App;
