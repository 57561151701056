import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import HomeFreelancer from "../views/all-home-version/HomeFreelancer";
import Contact from "../views/inner-pages/Contact";
import Faq from "../views/inner-pages/Faq";
import NotFound from "../views/inner-pages/NotFound";
// Service
import Service from "../views/inner-pages/service/Service";
import ServiceDetails from "../views/inner-pages/service/ServiceDetails";
// Portfolio
import WorksGrid from "../views/inner-pages/portfolio/WorksGrid";
import WorksMasonry from "../views/inner-pages/portfolio/WorksMasonry";
import WorksListing from "../views/inner-pages/portfolio/WorksListing";
import WorksCarousel from "../views/inner-pages/portfolio/WorksCarousel";
import WorksShowcase from "../views/inner-pages/portfolio/WorksShowcase";

const AllRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<HomeFreelancer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/services" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        {/* Portfolio */}
        <Route path="/works-grid" element={<WorksGrid />} />
        <Route path="/works-masonry" element={<WorksMasonry />} />
        <Route path="/works-listing" element={<WorksListing />} />
        <Route path="/works-carousel" element={<WorksCarousel />} />
        <Route path="/works-showcase" element={<WorksShowcase />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  );
};

export default AllRoutes;
